import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FaFacebook, FaInstagram } from 'react-icons/fa';
import slika from '../img/zvezda-s-orange.png';
import Fade from 'react-reveal/Fade';

function Header() {
  return (
    <div className='container p-0'>
      <header className="d-flex justify-content-between align-items-center p-3">
        {/* Logo */}
        <div>
          <img src={slika} alt="Zvezda-S" className='logo' style={{ height: '100px' }} />
        </div>
        {/* Ikone društvenih mreža */}
        <div className="d-flex align-items-center">
          <a href="https://www.instagram.com/zvezda_s_doo/" target="_blank" rel="noopener noreferrer" className="mx-2 text-light icon">
            <FaInstagram size={24} />
          </a>
          <a href="https://www.facebook.com/zvezdas.doo.94/" target="_blank" rel="noopener noreferrer" className="mx-2 text-light icon">
            <FaFacebook size={24} />
          </a>
        </div>
      </header>
      {/* Navigacija */}
      <nav className="navbar navbar-expand-lg navbar-dark mb-4 p-2">
        <div className="container-fluid">
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav ml-auto flex-row">
              <li className="nav-item mx-2">
               <Fade left duration={400}><a className="nav-link" href="/#Home">Početna</a></Fade> 
              </li>
              <li className="nav-item mx-2">
              <Fade left duration={800}><a className="nav-link" href="#about">O nama</a></Fade> 
              </li>
              <li className="nav-item mx-2">
              <Fade left duration={1100}><a className="nav-link" href="#services">Asortiman</a></Fade> 
              </li>
              <li className="nav-item mx-2">
              <Fade left duration={1400}><a className="nav-link" href="#gallery">Galerija</a></Fade> 
            </li>
              <li className="nav-item mx-2">
              <Fade left duration={1700}><a className="nav-link" href="#contact">Kontakt</a></Fade> 
              </li>
            </ul>
          </div>
          {/* Na mobilnim uređajima, menija su uvek vidljivi */}
          <ul className="navbar-nav d-lg-none flex-row">
            <li className="nav-item mx-2">
            <Fade left><a className="nav-link" href="/#Home">Početna</a></Fade> 
            </li>
            <li className="nav-item mx-2">
            <Fade left><a className="nav-link" href="#about">O nama</a></Fade> 
            </li>
            <li className="nav-item mx-2">
            <Fade left> <a className="nav-link" href="#services">Asortiman</a></Fade> 
            </li>
            <li className="nav-item mx-2">
            <Fade left><a className="nav-link" href="#gallery">Galerija</a></Fade> 
            </li>
            <li className="nav-item mx-2">
            <Fade left><a className="nav-link" href="#contact">Kontakt</a></Fade> 
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
}

export default Header;
