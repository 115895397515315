import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faMapMarkerAlt,  faHandshake, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="container">
                <div className="row">
                    <div className="col-md-3">
                        <h2>O Nama</h2>
                        <p>Istorija našeg preduzeća tesno je povezana sa istorijom razvoja preduzeća Zvezda doo, osnovanog 2000-tih godina kao jednog od prvih velikih privatnih preduzeća na teritoriji naše opštine. Od njegovog razvoja, do današnjeg dana, trgovina proizvodima navedenih delatnosti biva i ostaje dominantna delatnost našeg preduzeća.</p>
                        
                    </div>
                    <div className="col-md-3">
                        <h2>Partneri</h2>
                        <ul className="list-unstyled">
                            <li className='li-b pb-2'><a href="https://www.pvftraders.com/" className='a'><FontAwesomeIcon icon={faHandshake} className='icon'/> PVF Traders </a></li>
                            <li className='li-b pb-2'><a href="https://www.hydrosystems.rs/home/#/" className='a'><FontAwesomeIcon icon={faHandshake} className='icon'/> Hydro Systems </a></li>
                            <li className='li-b pb-2'><a href="https://feros.co.rs/" className='a'><FontAwesomeIcon icon={faHandshake} className='icon'/> Feros </a></li>
                            <li className='li-b pb-2'><a href="https://www.mipal.co.rs/" className='a'><FontAwesomeIcon icon={faHandshake} className='icon'/> Mipal </a></li>
                            <li className='li-b pb-2'><a href="https://www.3dmetaldoo.rs/" className='a'><FontAwesomeIcon icon={faHandshake} className='icon'/> 3D-Metal </a></li>
                            <li className='li-b pb-2'><a href="https://www.termovent.co.rs/shop/" className='a'><FontAwesomeIcon icon={faHandshake} className='icon'/> Termovent </a></li>
                            <li className='li-b pb-2'><a href="https://polyagram.rs/sr/" className='a'><FontAwesomeIcon icon={faHandshake} className='icon'/> Polyagram  </a></li>
                            <li className='li-b pb-2'><a href="https://www.elektronapon.rs/" className='a'><FontAwesomeIcon icon={faHandshake} className='icon'/> Elektro Napon  </a></li>
                        </ul>
                    </div>
                    <div className="col-md-3">
                        <h2>Kontakt</h2>
                        <ul className="list-unstyled">
                            <li className='li1'><a href="tel:+381 034720242"><FontAwesomeIcon icon={faPhone} className='f-icon icon' /></a><br /> 034 720 242 - Aranđelovac<br /> 065 87 02 600 - Aranđelovac<br />069 40 78 364 - Mladenovac</li>
                            <li className='li1'><a href="mailto:slavisa.zvezda@gmail.com"><FontAwesomeIcon icon={faEnvelope} className='f-icon icon' /></a><br /> slavisa.zvezda@gmail.com - Aranđelovac<br />zvezdafinansije@gmail.com - Aranđelovac<br />zvezdasmladenovac@gmail.com - Mladenovac</li>
                            <li className='li1'><a href="#contact"><FontAwesomeIcon icon={faMapMarkerAlt} className='f-icon icon' /></a><br /> Zanatlijska 2, Aranđelovac<br />Njegoševa 33, Mladenovac</li>
                            
                        </ul>
                    </div>
                    <div className="col-md-3">
                        <h2>Radno Vreme</h2>
                        <table className="table">
                            <tbody className='tb'>
                                <tr><td className='td2'>Ponedeljak</td><td className='td2'>07:30 - 19:30</td></tr>
                                <tr><td className='td2'>Utorak</td><td className='td2'>07:30 - 19:30</td></tr>
                                <tr><td className='td2'>Sreda</td><td className='td2'>07:30 - 19:30</td></tr>
                                <tr><td className='td2'>Četvrtak</td><td className='td2'>07:30 - 19:30</td></tr>
                                <tr><td className='td2'>Petak</td><td className='td2'>07:30 - 19:30</td></tr>
                                <tr><td className='td2'>Subota</td><td className='td2'>07:30 - 16:00</td></tr>
                                <tr><td className='td2'>Nedelja</td><td className='td1'>07:30 - 12:00</td></tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="d-flex justify-content-center pt-2 pb-3">
                    <Link to="/privacy-policy" className="mx-3 ftxt">Politika privatnosti</Link>
                    <Link to="/cookie-policy" className="mx-3 ftxt">Kolačići</Link>
                 </div>
            </div>
        </footer>
    );
}

export default Footer;
