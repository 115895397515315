import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Fade from 'react-reveal/Fade';

const Counter = () => {
  const [customers, setCustomers] = useState(0);
  const [products, setProducts] = useState(0);

  useEffect(() => {
    const customerInterval = setInterval(() => {
      setCustomers(prev => Math.min(prev + 450, 100000)); // Count up to 20,000
    }, 50); // Update every 50 ms

    const productInterval = setInterval(() => {
      setProducts(prev => Math.min(prev + 30, 3600)); // Count up to 3,000
    }, 50); // Update every 50 ms

    return () => {
      clearInterval(customerInterval);
      clearInterval(productInterval);
    };
  }, []);

  return (
    <div  style={{ backgroundColor: '#d66733'}}>
    <div className="container text-center ">
      <div className="row m-0">
        <div className="col-md-6 m-0 p-0">
          <div className="counter-box" style={{ backgroundColor: '#d66733', padding: '40px' }}>
         <Fade top><p style={{ fontSize: '1.25rem' }}>Preko </p></Fade> 
         <Fade top> <h3 style={{ fontSize: '3rem' }} className='bold-text'>{customers.toLocaleString()} </h3></Fade> 
         <Fade top>  <p style={{ fontSize: '1.25rem' }}>Zadovoljnih kupaca </p></Fade> 
          </div>
        </div>
        <div className="col-md-6 m-0 p-0">
          <div className="counter-box" style={{ backgroundColor: '#d66733', padding: '40px' }}>
          <Fade top> <p style={{ fontSize: '1.25rem' }}> Preko </p></Fade> 
          <Fade top> <h3 style={{ fontSize: '3rem' }} className='bold-text'> {products.toLocaleString()}</h3></Fade> 
          <Fade top> <p style={{ fontSize: '1.25rem' }}> Artikala </p></Fade> 
          </div>
        </div>
      </div>
    </div>

    </div>
  );
};

export default Counter;
