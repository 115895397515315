import React from 'react';
import '../App.css'; // Stilizacija komponente
import '@fortawesome/fontawesome-free/css/all.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faTruck, faBoxOpen } from '@fortawesome/free-solid-svg-icons'; // Uvoz ikonica koje želite koristiti

const LocationCard = () => {
  return (
    <div className='infoback'>
      <div className="container infoY">
        <div className="row d-flex">
          <div className="col-md-4 p-0 d-flex">
            <div className="card mb-0 mb-lg-3 flex-fill pt-3">
              <div className="card-body text-center">
                <FontAwesomeIcon icon={faMapMarkerAlt} className="location-icon icon" />
                <h5 className="card-title mt-3">Lokacija</h5>
                <p className="card-text">Zanatlijska 5, Aranđelovac<br />Njegoševa 33, Mladenovac</p>
              </div>
            </div>
          </div>
          <div className="col-md-4 p-0 d-flex">
            <div className="card mb-0 mb-lg-3 flex-fill pt-3">
              <div className="card-body text-center">
                <FontAwesomeIcon icon={faTruck} className="location-icon icon" />
                <h5 className="card-title mt-3">Dostava na adresu</h5>
                <p className="card-text">Sigurno i pouzdano stiže do vas!</p>
              </div>
            </div>
          </div>
          <div className="col-md-4 p-0 d-flex">
            <div className="card mb-0 mb-lg-3 flex-fill pt-3">
              <div className="card-body text-center">
                <FontAwesomeIcon icon={faBoxOpen} className="location-icon icon" />
                <h5 className="card-title mt-3">Širok asportiman artikala</h5>
                <p className="card-text">Nudimo širok asortiman artikala vrhunskog kvaliteta po povoljnim cenama. Pronađite sve što vam je potrebno na jednom mestu!</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LocationCard;
