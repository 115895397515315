import './App.css';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import PrivacyPolicy from './pages/privacy-policy.js'
import CookiePolicy from './pages/cookie-policy.js';
import Home from './pages/Home.js';
import Header from './components/Header.jsx';
import Footer from './components/Footer.jsx';

function App() {
  
  return (
    <div className='App'>

<Header />
      <Routes>
      <Route exact path="/" element={<Home />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/cookie-policy" element={<CookiePolicy />} />
      </Routes>
<Footer />
    </div>
  );
}

export default App;
