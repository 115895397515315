import React from 'react';
import { Carousel } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import slika1 from '../img/2.jpg';
import slika3 from '../img/slide2.jpg';
import slika2 from '../img/slide3.jpg';
import Fade from 'react-reveal/Fade';

const ImageSlider = () => {
  return (
    <Carousel>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={slika1}
          alt="Čelične cevi visoke kvalitete iz Gvoždjare Zvezda-S"
        />
        <Carousel.Caption>
          <Fade top>
            <h5>Izdržljivost u svakom delu</h5>
            <p>Zvezda-S vam nudi širok asortiman metalnih cevi, dizajniranih da traju i podnose najveće izazove.</p>
          </Fade>
        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item>
        <img
          className="d-block w-100"
          src={slika2}
          alt="Vodovod i kanalizacija iz ponude Zvede-S"
        />
        <Carousel.Caption>
          <Fade top>
            <h5>Kompletna oprema za vaše gradilište</h5>
            <p>Od vodovodnih cevi do kanalizacione opreme, naša ponuda pruža sve što vam je potrebno za efikasnu i sigurnu instalaciju.</p>
          </Fade>
        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item>
        <img
          className="d-block w-100"
          src={slika3}
          alt="Kupatilska oprema alati"
        />
        <Carousel.Caption>
          <Fade top>
            <h5>Oprema i alati za svaki projekt</h5>
            <p>S širokim asortimanom kupatilske opreme, alata i instalacijskih materijala, Zvezda-S je vaš partner za svaki korak gradnje i renoviranja.</p>
          </Fade>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
};

export default ImageSlider;
