import React from 'react';
import slika1 from '../img/pestan.png';
import slika2 from '../img/jeep.png';
import slika3 from '../img/unipromet.png';
import slika4 from '../img/rosan.png';
import slika5 from '../img/termorad.png';
import slika6 from '../img/minotti.png';
import Fade from 'react-reveal/Fade';


const ClientLogos = () => {
  return (
    <div style={{ backgroundColor: 'white', padding: '20px' }} className="pad100-70-top-bottom client_hover_1">
      <div className="container">
        <div className="row client_hover">
          <div className="col-md-2 col-sm-4 col-6 mb-4 mt-4">
          <span className="client_img image_hover">
          <Fade left duration={600}><a href='https://pestan.net/sr/'><img src={slika1} className="img-responsive zoom_img_effect" alt="Pestan" /></a></Fade> 
            </span>
          </div>
          <div className="col-md-2 col-sm-4 col-6 mb-4 mt-4">
            <span className="client_img image_hover">
            <Fade left duration={800}><a href='https://jeepcommerce.rs/'><img src={slika2} className="img-responsive zoom_img_effect" alt="JeepCommerce" /></a></Fade>
            </span>
          </div>
          <div className="col-md-2 col-sm-4 col-6 mb-4 mt-4">
            <span className="client_img image_hover">
            <Fade left duration={1000}><a href='https://unipromet.co.rs/'> <img src={slika3} className="img-responsive zoom_img_effect" alt="Unipromet" /></a></Fade>
            </span>
          </div>
          <div className="col-md-2 col-sm-4 col-6 mb-4 mt-4">
            <span className="client_img image_hover">
            <Fade left duration={1200}><a href='https://www.rosan.rs/'> <img src={slika4} className="img-responsive zoom_img_effect" alt="Rosan" /></a></Fade>
            </span>
          </div>
          <div className="col-md-2 col-sm-4 col-6 mb-4 mt-4">
            <span className="client_img image_hover">
            <Fade left duration={1400}> <a href='https://www.termorad.com/'><img src={slika5} className="img-responsive zoom_img_effect" alt="Termorad" /></a></Fade>
            </span>
          </div>
          <div className="col-md-2 col-sm-4 col-6 mb-4 mt-4">
            <span className="client_img image_hover">
            <Fade left duration={1600}> <a href='https://minotti.rs/'><img src={slika6} className="img-responsive zoom_img_effect" alt="Minotti" /></a></Fade>
            </span>
          </div>
          
        </div>
      </div>
    </div>
  );
};

export default ClientLogos;
