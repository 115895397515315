import './App.css';
import React from 'react';
import '../components/Header.jsx';
import LocationCard from '../components/Info.jsx';
import Map from '../components/Map.jsx';
import BackToTopButton from '../components/BackToTopButton.jsx';
import UslugeSlider from '../components/UslugeSlider.jsx';
import ImageTextComponent from '../components/ImageText.jsx';
import CountdownComponent from '../components/Counter.jsx';
import ImageGallery from '../components/ImageGallery.jsx';
import ClientLogos from '../components/ClientLogos.jsx';
import ImageSlider from '../components/ImageSlider.jsx';

function Home() {


  return (

    <div className='App'>
    <div id='home'>
    <ImageSlider />
    <LocationCard />
    </div>
    <div id='about'>
    <ImageTextComponent />
    <CountdownComponent />
    </div>
    <div id='services'>
    <UslugeSlider />
    <div id='gallery'>
    <ImageGallery />
    </div>
    </div>
    <div id='contact'>
    <ClientLogos />
    <Map />
    <BackToTopButton />
    </div>
    </div>
  );
}

export default Home;
