import React from 'react';
import { Fade } from 'react-reveal';

const Map = () => {
    return (

        <section className="contact-page-map wow fadeInUp delay-0-2s">
            <div>
               <Fade duration={3000}><iframe src="https://maps.google.com/maps?q=44.301216,20.575645&z=15&output=embed&cid=0x47574d1f9642c55d:0x647f7eea0dd9c9d7" width="100%" height="450" frameborder="0"  title=' ' className='align-top'></iframe></Fade>
            </div>
        </section>
    );
};
export default Map;