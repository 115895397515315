import React from 'react';
import slika from '../img/kosmomi.webp';
import Fade from 'react-reveal/Fade';

const ImageTextComponent = () => {
  return (
    <div style={{ backgroundColor: 'white', padding: '20px' }}>
      <div className="container">
        <div className="row">
          <Fade left>
          <div className="col-md-6 mt-3">
            <img
              src={slika}
              alt="Zvezda-S"
              className="img-fluid1 img-fluid"
              style={{ marginBottom: '20px' }}
            />
          </div>
          </Fade>
          <Fade right>
          <div className="col-md-6 mt-3">
            <h2 style={{ marginTop: '20px' , color: '#d66733'}} className='bold-text'>KO SMO MI</h2>
            <p style={{ marginTop: '20px' }}>
              U srcu Aranđelovca, preduzeće Zvezda-S doo nosi tradiciju kvalitetne trgovine crne metalurgije, vodovoda i kanalizacije, sanitarijama, elektro materijalima, alatima i građevinskim materijalima. Osnovano u 2000-im godinama, postali smo jedno od vodećih privatnih preduzeća u regionu, posvećeni zadovoljstvu naših kupaca. Naša raznolika ponuda uključuje čelične cevi, aluminijumske radijatore, sanitarije i alat, omogućavajući vam da pronađete sve što vam je potrebno na jednom mestu. Pored toga, nudimo usluge isporuke na kućnu adresu, olakšavajući vam proces kupovine. Posvećeni smo pružanju visokog kvaliteta proizvoda i usluga, te stvaranju dugoročnih odnosa sa našim klijentima.
            </p>
          </div>
          </Fade>
        </div>
      </div>
    </div>
  );
};

export default ImageTextComponent;
