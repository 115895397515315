import React, { useState } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import slika1 from '../img/crna-metalurgija.jpg';
import slika2 from '../img/kanalizacija.jpg';
import slika3 from '../img/kupatilo.jpg';
import slika4 from '../img/baterije.jpg';
import slika5 from '../img/makita.webp';
import slika6 from '../img/bojleri.jpg';
import Fade from 'react-reveal/Fade';

const galerija1 = [
  '../img/IMG_5011.jpg',
  '../img/IMG_5010.jpg',
  '../img/IMG_5030.jpg',
  '../img/IMG_5009.jpg',
];

const galerija2 = [
  '../img/IMG_5025.JPG',
  '../img/IMG_5028.JPG',
  '../img/IMG_5020.JPG',
  '../img/IMG_5024.JPG',
  '../img/IMG_5029.JPG',
];

const galerija3 = [  
  '../img/IMG_5015.JPG',
  '../img/IMG_5031.JPG',
  '../img/IMG_5016.JPG',
];

const galerija4 = [ 
  '../img/IMG_5027.JPG',
  '../img/IMG_5019.JPG',
  '../img/IMG_5018.JPG',
];

const galerija5 = [ 
  '../img/IMG_5034.JPG',
  '../img/IMG_5008.JPG',
  '../img/IMG_5026.JPG',
  '../img/makita.webp',
];

const galerija6 = [
  '../img/IMG_5032.JPG',
  '../img/IMG_5012.JPG',
  '../img/IMG_5033.JPG',
];

const ImageGallery = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [currentGallery, setCurrentGallery] = useState([]);
  const [transformStyle, setTransformStyle] = useState({});
  const [hoveredIndex, setHoveredIndex] = useState(null); // Dodato za praćenje indeksa slike

  const openLightbox = (gallery) => {
    setCurrentGallery(gallery);
    setPhotoIndex(0);
    setIsOpen(true);
  };

  const handleMouseMove = (e, index) => {
    const { offsetWidth: width, offsetHeight: height } = e.currentTarget;
    const { offsetX: x, offsetY: y } = e.nativeEvent;

    const rotateX = ((y / height) - 0.5) * -25;
    const rotateY = ((x / width) - 0.5) * 25;

    if (hoveredIndex === index) {
      setTransformStyle({
        transform: `rotateX(${rotateX}deg) rotateY(${rotateY}deg) scale(1.03)`,
      });
    }
  };

  const handleMouseLeave = () => {
    setTransformStyle({ transform: 'rotateX(0deg) rotateY(0deg) scale(1)', transition: 'transform 0.3s ease' });
  };

  return (
    <div style={{ backgroundColor: '#282828', padding: '20px' }}> 
      <div className="container my-4">
        <h1 className='tim1'>GALERIJA</h1>
        <div className="row">
          <div className="col-md-4 mb-4 mt-4" onClick={() => openLightbox(galerija1)}>
            <div 
              className="image-container" 
              onMouseMove={(e) => handleMouseMove(e, 1)}
              onMouseLeave={handleMouseLeave}
              onMouseEnter={() => setHoveredIndex(1)}
              style={hoveredIndex === 1 ? transformStyle : {}}
            >
              <Fade top><img src={slika1} alt="Crna metalurgija" className="img-fluid" style={{ cursor: 'pointer', width: '100%', height: '280px', objectFit: 'cover' }} /></Fade>
            </div>
          </div>
          <div className="col-md-4 mb-4 mt-4" onClick={() => openLightbox(galerija2)}>
            <div 
              className="image-container"
              onMouseMove={(e) => handleMouseMove(e, 2)}
              onMouseLeave={handleMouseLeave}
              onMouseEnter={() => setHoveredIndex(2)}
              style={hoveredIndex === 2 ? transformStyle : {}}
            >
              <Fade top><img src={slika2} alt="Vodovod i kanalizacija" className="img-fluid" style={{ cursor: 'pointer', width: '100%', height: '280px', objectFit: 'cover' }} /></Fade>
            </div>
          </div>
          <div className="col-md-4 mb-4 mt-4" onClick={() => openLightbox(galerija3)}>
            <div 
              className="image-container"
              onMouseMove={(e) => handleMouseMove(e, 3)}
              onMouseLeave={handleMouseLeave}
              onMouseEnter={() => setHoveredIndex(3)}
              style={hoveredIndex === 3 ? transformStyle : {}}
            >
              <Fade top><img src={slika3} alt="Kupatilska oprema" className="img-fluid" style={{ cursor: 'pointer', width: '100%', height: '280px', objectFit: 'cover' }} /></Fade>
            </div>
          </div>
          <div className="col-md-4 mb-4 mt-4" onClick={() => openLightbox(galerija4)}>
            <div 
              className="image-container"
              onMouseMove={(e) => handleMouseMove(e, 4)}
              onMouseLeave={handleMouseLeave}
              onMouseEnter={() => setHoveredIndex(4)}
              style={hoveredIndex === 4 ? transformStyle : {}}
            >
              <Fade bottom><img src={slika4} alt="Tusevi i baterije" className="img-fluid" style={{ cursor: 'pointer', width: '100%', height: '280px', objectFit: 'cover' }} /></Fade>
            </div>
          </div>
          <div className="col-md-4 mb-4 mt-4" onClick={() => openLightbox(galerija5)}>
            <div 
              className="image-container"
              onMouseMove={(e) => handleMouseMove(e, 5)}
              onMouseLeave={handleMouseLeave}
              onMouseEnter={() => setHoveredIndex(5)}
              style={hoveredIndex === 5 ? transformStyle : {}}
            >
              <Fade bottom><img src={slika5} alt="Alati" className="img-fluid" style={{ cursor: 'pointer', width: '100%', height: '280px', objectFit: 'cover' }} /></Fade>
            </div>
          </div>
          <div className="col-md-4 mb-4 mt-4" onClick={() => openLightbox(galerija6)}>
            <div 
              className="image-container"
              onMouseMove={(e) => handleMouseMove(e, 6)}
              onMouseLeave={handleMouseLeave}
              onMouseEnter={() => setHoveredIndex(6)}
              style={hoveredIndex === 6 ? transformStyle : {}}
            >
              <Fade bottom><img src={slika6} alt="Bojleri i vodene pumpe" className="img-fluid" style={{ cursor: 'pointer', width: '100%', height: '280px', objectFit: 'cover' }} /></Fade>
            </div>
          </div>
        </div>

        {isOpen && (
          <Lightbox
            mainSrc={currentGallery[photoIndex]}
            nextSrc={currentGallery[(photoIndex + 1) % currentGallery.length]}
            prevSrc={currentGallery[(photoIndex + currentGallery.length - 1) % currentGallery.length]}
            onCloseRequest={() => setIsOpen(false)}
            onMovePrevRequest={() => setPhotoIndex((photoIndex + currentGallery.length - 1) % currentGallery.length)}
            onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % currentGallery.length)}
          />
        )}
      </div>
    </div>
  );
};

export default ImageGallery;
